.App {
  text-align: center;
}

:root {
  --background-color: #F3F4F6; /* Light mode background color */
  --text-color: #000000; /* Light mode text color */
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  background-color: #F3F4F6;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #F3F4F6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.right-side{
  background: white ;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px #e3dfdf;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


#recievingDep > tbody > tr > td{
font-size: 17px !important;
  padding: 20px !important;
  text-wrap: nowrap;  
}

#recievingDep > thead > tr > th{
  font-size: 17px !important;
  font-weight: bold;
  padding: 20px !important;
  text-wrap: nowrap;
  }

  .table_component >thead>tr>th{
   text-wrap:nowrap !important ;
  }