.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 9vh;
  }
  
  .spinner {
    animation: rotate-spinner 1.5s infinite ease-in-out;
  }
  
  @keyframes rotate-spinner {
    0% {
      transform: rotate(0deg);
      scale: 1;
    }
    50% {
      transform: rotate(180deg);
      scale: 1.1; /* Slight scaling for a bounce effect */
    }
    100% {
      transform: rotate(360deg);
      scale: 1;
    }
  }
  