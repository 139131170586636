@media print {
  #printButton {
    display: none;
  }

  
 
  #barcode-div {
    page-break-after: always; /* Add page break after each barcode */
  }

  #article {
    font-size: 7px;
    font-weight: bold;
    top: -20px;
    left: 0;
    right: 0;
    position: relative;
  }

  #code {
    font-size: 8px;
  }
}

@media print {
  .hide-in-pdf {
    display: none !important;
  }
}
